.searchInputAddrRes {
    display: block;
    position: absolute;
    top: 37px;
    left: 0px;
    right: 0px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: 10px;
    height: 490px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    overflow-y: auto;
    z-index: 99999;
}

.searchInputAddrSearch {
    top: 32px;
    left: 5px;
    right: 5px;
    position: absolute;
    z-index: 999;
}

.searchInputAddrLabel {
    margin-bottom: .5em;
}

.searchInputAddr {
    position: relative;
}

.transparentBlock {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 10;
}

.textCenter {
    text-align: center;
}

.addButton {
    width: 100%;
    text-align: left;
}

.dropdown {
    width: 100%;
}