.menu-left,
.menu-right {
    top: 0px;
    float: left;
    left: 0;
    bottom: 0;
    padding-top: 75px;
    /*transform: translateY(125%);*/
    position: fixed;
    display: block;
    text-align: left;
}

.menu-left {
    background: #343a40;
    background: url(./images/bg-2.jpg) top left;
    display: block;
    white-space: nowrap;
    width: 50px;
    transition: width .5s;
    font-weight: bold;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
    z-index: 1000;
}


.menu-left:hover {
    /*width: 320px;*/
}

.menu-menu-left .item:hover {
    background-color: #222;
}

.menu-left .fas {
    margin: 15px;
    width: 20px;
    /*color: #fff;*/
}

.menu-left i.fas {
    font-size: 17px;
    vertical-align: middle !important;
}

.menu-left .item {
    height: 35px;
    overflow: hidden;
    color: #fff;
    cursor: pointer;
    display: block;
}

.menu-left .item {
    color: #fff !important;
    cursor: pointer;
    display: block;
}

.menu-left .item:hover {
    color: #CCCCff;
    text-decoration: none;
}

.menu-left .item.active {
    color: #8888ff !important;
    text-decoration: none;
}

.menu-left-bottom {
    display: block;
    position: fixed;
    bottom: 5px;
}

.version, .version p {
    text-align: center;
    width: 50px;
    padding-top: 1px;
    font-size: 12px;
    color: #FFFFFF;
    margin-bottom: 0;
    font-weight: normal;
}

.nav-bar {
    background: url(./images/bg-2.jpg) top left !important;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5) !important;
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    z-index: 1002 !important;
    max-height: 60px;
}

.content {
    transition: padding .5s;
}

.content-open {
    padding-left: 250px;
}

.menu-left-open {
    width: 250px;
}
