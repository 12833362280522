.login-bg{
    height: 100%;
    background-color: #282c34;
    background: url(./images/bg-2.jpg) top left;
    background-size: cover;
}

#root{
    height: 100%;
}

.App {
    text-align: center;
    height: 100%;
}

.App-logo {
    max-width: 250px;
    min-width: 200px;
    width: 100%;
    pointer-events: none;
    margin: auto;
}

.App-header {
    background-color: #282c34;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Login{
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    left: 10px;
    margin: auto;
    max-width: 575px;
    height: 400px;
}

.left{
    text-align: left !important;
}

.right{
    text-align: right !important;
}

.login-form{
    min-width: 250px;
    width: 100%;

}

.per100{
    width: 100%;
}

.login-card{
    box-shadow: 0px 0px 55px 2px rgba(0, 0, 0, 200);
}

.flex{
    display: flex;
}