.searchBox {
    top: 0;
    right: 5px;
    left: 5px;
    position: absolute;
    z-index: 99;
}

.searchBox div:first-child {
    z-index: 99;
}

.searchInputRes {
    display: block;
    position: absolute;
    top: 37px;
    width: 100%;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: 10px;
    height: 200px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    overflow-y: auto;
    z-index: 999;
}