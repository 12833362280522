.input-file-group {
    margin-left: 0.25rem;;
}

.input-file-group label {
    margin-bottom: inherit;
}

.input-file {
    width:.1px;
    height:.1px;
    opacity:0;
    overflow:hidden;
    position:absolute;
    z-index:-1;
}