.devIcon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: url(../../images/sprite.png);
    vertical-align: middle;
}


.modem {
    background-position: -64px -256px;
}

.leakage {
    background-position: -64px -288px;
}

.sensor {
    background-position: -128px -256px;
}

.valve {
    background-position: -256px -64px;
}