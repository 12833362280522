.root {
    display: flex;
    width: 100%;
    max-width:100%;
    overflow: hidden;
    flex-wrap: wrap;
}

.icon {
    width: 80px;
}

.file {
    width: 100px;
    margin: 15px;
    display: flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;
}

.imageBox {
    min-height: 90vh;
    width: 100%;
    object-fit: scale-down;
}

.delButton {
    position: absolute;
    margin-right: -70px;
}

.dropZone {
    height: 100%;
    width: 100%;
}

.activeDropZone {
    height: 100%;
    width: 100%;
    border: dashed blue;
}

.title {
    width: 110%;
    word-wrap: break-word;
}