html, body {
    height: 100%;
    background-color: #CCCCCC;
}

#root {
    height: 100%;
}

.App {
    text-align: left !important;
    height: 100%;
    background-color: #CCCCCC;
}

.App-logo {
    max-width: 250px;
    min-width: 200px;
    width: 100%;
    pointer-events: none;
    margin: auto;
}


.App-link {
    color: #61dafb;
}

.status-div {
    width: 250px;
    /*height: 105px;*/
    /* margin: 5px auto;*/
    position: absolute;
    right: 15px;
    top: 150px;
    padding: 5px;
    border-radius: 5px;
    background-color: #fafafa;
}

.active {
    font-weight: bold;
}

h1 {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    color: #CCCCCC !important;
    text-align: center;
    position: fixed;
    z-index: 1003;
    top: 5px;
    left: 250px;
    right: 250px;
    /*width: 100%;*/
}

.navbar-brand {
    z-index: 19999;
    display: block;
}

.content {
    margin-left: 80px;
    margin-top: 0px;
    padding-top: 80px;
    margin-right: 25px;
    background-color: #CCCCCC;
}

/*.topalert {
    position: absolute !important;
    left: 75px !important;
    right: 25px !important;
    top: 235px !important;
    z-index: 100 !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;

}*/

.card-title {
    font-size: 45px !important;
}

.card-subtitle {
    font-size: 1.25rem !important;
}

.userName {
    width: 190px;
    color: white;
    font-size: 16px;
    padding: 0;
    margin: 0;
    padding-right: 10px;
    word-wrap: normal;
    display: inline-block;
    text-align: right;
}

.userName a {
    color: white !important;
}

.userBtn {
    vertical-align: baseline;
}

.modalFooter {
    text-align: right;
    margin-bottom: -20px;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-right: -15px !important;
    margin-left: -15px !important;
    margin-top: 20px;
}

.btn.focus, .btn:focus,
.btn-secondary.focus, .btn-secondary:focus,
.btn-danger.focus, .btn-danger:focus,
.btn-success.focus, .btn-success:focus {
    outline: 2px solid #80bdff !important;
}

.btn:focus, input:focus {
    box-shadow: none !important;
}

.btnAdd {
    width: 60px !important;
    height: 60px !important;
    font-size: 24px !important;
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 10;
}
.btnAdd:focus, .btnAdd.focus {
    outline: none !important;
    box-shadow: 0 0 0 2px #80bdff !important;
}

.btnAddSmall {
    width: 30px !important;
    height: 30px !important;
    font-size: 12px !important;
    position: absolute;
    left: 5px;
    top: 5px;
    padding: 3px !important;
}

.btnAddSmallu {
    width: 30px !important;
    height: 30px !important;
    font-size: 12px !important;
    padding: 0.25rem !important;
    margin-left: 5px;
    margin-bottom: 3px;
}

.btnAddSmallu i {
    font-size: 1.3rem;
}

.btnAddSmall2 {
    width: 20px !important;
    height: 20px !important;
    font-size: 12px !important;
    padding: 0.05rem !important;
    margin-left: 0px;
    margin-top: -3px;
}

.btnAddSmall2 i {
    font-size: 0.9rem;
}

.smallTopR {
    margin-top: -67px !important;
    text-align: right !important;
    margin-bottom: 37px !important;
}

.searchInputInSrv {
    margin: auto !important;
    position: sticky !important;
    top: 76px !important;
    margin-top: -55px !important;
    right: 0px !important;
    margin-right: 0 !important;
    width: 350px !important;
    margin-bottom: 5px !important;
    z-index: 999 !important;
}

.searchInputInSrv .search-input-obj {
    padding: 0 10px 10px 10px;
}

.searchInputInSrv .search-input-obj:first-child {
    margin-top: 15px;
}

.searchInputInSrv .search-input-ibj-res {
    left: 10px;
    right: 10px;
}

.searchInputInMkdFrm {
    margin: auto !important;
    position: sticky !important;
    top: 76px !important;
    margin-top: -64px !important;
    right: 0px !important;
    margin-right: 0 !important;
    width: 350px !important;
    margin-bottom: 5px !important;
    z-index: 999 !important;
}

.navInSrv {
    position: sticky;
    top: 70px;
    z-index: 998;
}

.navInSrv ol {
    margin: 10px -5px;
    padding-left: 10px;
    height: 51px
}

.centerCount {
    width: 200px;
    margin: auto;
    position: sticky;
    right: 0;
    top: 84px;
    display: block;
    z-index: 1005;
    left: 0;
}

.countersTh {
    display: block;
    right: 0;
    left: 0;
    top: 110px;
    position: sticky;
    margin: 0 0 -30px 0 !important;
    z-index: 997;
}

.tabContent {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    border: 1px solid #dee2e6;
    border-top: 1px solid transparent;
    margin-top: -3px;
    padding: 5px;
}

.tabContent .active {
    font-weight: normal !important;
}

.dropdown-menu-lg-right {
    left: auto !important;
}

.chbirow_t {
    position: relative;
    margin-top: 35px !important;
}

.chbirow_t label {
    margin-left: 5px;
}


@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px !important;
    }
}

@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 800px;
    }
}

.transition500 {
    transition: all 800ms ease;
}

.blur {
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    filter: blur(3px);
}

.navbar.bg-light {
    border: none !important;
}

.table {
    color: #0d0d0d !important;
}

.form-control {
    background: linear-gradient(#fff, #f2f4f7) !important;
    border: 1px solid #ced0da;
    outline: none !important;
    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis; /* Добавляем многоточие */
    cursor: pointer;
    min-height: 38px;
}

textarea.form-control {
    white-space: break-spaces;
}

.btn-default {
    background: linear-gradient(#fff, #f2f4f7) !important;
    border: 1px solid #ced0da !important;
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
}

.res20 {
    background-size: 14px;
    background-position: center 3px;
    background-repeat: no-repeat;
    width: 20px;
    height: 28px;
    margin: 0;
    margin-bottom: -11px;
}

.res16 {
    background-size: 10px;
    background-position: center 3px;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin: 0;
    margin-bottom: -2px;
}

.res10 {
    background-size: 12px;
    background-position: center 2px;
    background-repeat: no-repeat;
    width: 16px;
    height: 17px;
    margin-top: -8px;
    margin-bottom: -5px;
}

.res45 {
    background-size: 40px;
    background-position: center 12px;
    background-repeat: no-repeat;
    width: 45px;
    height: 70px;
    margin-top: -12px;
    margin-bottom: -16px;
}

.res-electro {
    background-image: url(./images/res/electro.png);
}

.res-w-cold {
    background-image: url(./images/res/w-cold.png);
}

.res-w-hot {
    background-image: url(./images/res/w-hot.png);
}

.res-gaz {
    background-image: url(./images/res/gaz.png);
}

.res-colorimetr {
    background-image: url(./images/res/colorimetr.png);
}

.s-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(./images/sprite.png);
    vertical-align: middle;
}

.icon-bs {
    background-position: -224px 0px;
}

.zoneSearchInputRes {
    display: block;
    position: absolute;
    top: 42px;
    width: calc(100% - 10px);
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: 10px;
    height: 150px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    overflow-y: auto;
}

.fade {
    transition: opacity 0.07s linear !important;
}

.pointer {
    cursor: pointer;
}

.guide_frame {
    width: calc(100% + 50px);
    height: calc(100vh - 80px);
    margin: -13px -50px -50px -29px;
}

.splash-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(200, 200, 200, 0.8);
    text-align: center;
    padding-top: 85px;
    font-weight: bold;
}

.full-screen-splash-block {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: calc(120px - 50vh);
    left: calc(75px - 50vw);
}

.p08 {
    padding-left: 0.8rem !important;
}


.form-control:disabled {
    opacity: 0.5;
    color: #AAAAAA;
    cursor: default;
}

div .disabled {
    opacity: 0.9;
    color: #AAAAAA;
    pointer-events: none;
}

.tarif_group {
    width: calc(100% - 25px);
    margin: 15px 12px;
    padding: 5px 8px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.tarif_group_title {
    font-weight: bold;
}

.splash-block-line {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: rgba(200, 200, 200, 0.8);
    text-align: center;
    padding-top: 10px;
    font-weight: bold;
    z-index: 999;
}

.form-control:focus {
    background: #ffffff !important;
}

.noRoundR {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.noRoundL {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.ThSel, .ThSel:focus, .ThSel:active {
    background: none !important;
    color: #fff !important;
    background-color: #212529 !important;
    border: none !important;
    font-weight: bold !important;
    padding: 0 !important;
    outline: none !important;
    margin-top: 0 !important;
    outline: 0 !important;
    outline-offset: 0 !important;
    box-shadow: none !important;
    height: 22px !important;
    margin-bottom: -15px !important;
}

.table .thead-dark th {
    color: #fff !important;
    background-color: #212529 !important;
    border-color: #32383e !important;
    vertical-align: middle !important;
    border: none;
}

.table .thead-dark tr {
    position: sticky;
    top: 130px;
    z-index: 2;
}

.btn {
    overflow: hidden !important;
}

.mkdStage {
    width: 100px;
    font-weight: bolder;
    vertical-align: middle !important;
    height: 100%;
    cursor: pointer;
    background-color: #fff;
}

.mkdPod {
    font-weight: bolder;
    vertical-align: middle !important;
    text-align: center;
    cursor: pointer;
}

.modal-max {
    max-width: 95% !important;
}

.mkd-table {
    border: 1px solid #dee2e6;
}

.mkd-counter, .mkd-bs {
    font-size: 10px;
    color: black;
}

.mkd-counter-drag {
    transform: rotate(3deg);
}

.mkd-bs {
    border: 1px solid #cfcfcf;
    cursor: pointer;
    margin-right: 3px;
    height: 22px;
}

.mkd-counter {
    background: #e0e0e0;
    cursor: pointer;
    margin-right: 3px;
    height: 22px;
}

.mkd-poded {
    background: #fff !important;
    border: none !important;
}

.btn-warning {
    color: #fff !important;
}

.pers_sogl button {
    margin: 0.25rem;
}

.lsInCounter {
    width: 100%;
}

.lsInCounter button:first-child {
    width: calc(100% - 32px);
    text-overflow: ellipsis;
    white-space: nowrap;
}

:root {
    --toastify-color-light: #fff !important;
    --toastify-color-dark: #121212 !important;
    --toastify-color-info: #2f96b4 !important;
    --toastify-color-success: #51a351 !important;
    --toastify-color-warning: #f89406 !important;
    --toastify-color-error: #bd362f !important;
}

.tab-content {
    height: 275px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

.no-scroll {
    overflow: visible;
}

.breadcrumb {
    background-color: #eee;
    border-radius: .25rem;
}
  
.row {
    margin-bottom: 10px;
}

.guid {
    width: 66.666667%;
}

.crud label {
    margin: 0;
}

.crud .btn-check:checked + .btn-primary {
    background-color: #51a351;
    border-color: #51a351;
    background-image: none;
}

.crud .btn-check + .btn-primary {
    background-color: #bd362f;
    border-color: #bd362f;
    background-image: none;
    font-size: smaller;
    line-height: 0.5;
    padding: 0.375rem;
}

.menu-left a {
    text-decoration: none;
}

.tab-content * {
    font-weight: normal;
}