.transfer-tech {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(./images/sprite.svg?v=3);
    background-size: 480px;
    vertical-align: middle;
}
.transfer-tech.nb {
    background-position: -115px 0;
    width: 38px;
    background-size: 1152px;
    height: 10px;
}
.transfer-tech.lora {
    background-position: -107px 0;
    width: 26px;
    height: 18px;
    background-size: 800px;
}

.transfer-tech.gsm2g {
    background-position: -19px 0;
    background-size: 558px;
    width: 18px;
    height: 10px;
}
.transfer-tech.gsm3g {
    background-position: 0 0;
    background-size: 558px;
    width: 19px;
    height: 10px;
}
.transfer-tech.lp {
    background-position: -220px 0;
    background-size: 1323px;
    width: 45px;
    height: 10px;
}
.transfer-tech.lte {
    background-position: -39px 0;
    background-size: 586px;
    width: 19px;
    height: 10px;
}