.crossControl {
    background: url(/src/images/center.gif) no-repeat;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 50%;
    top: 50%;
    margin: -8px 0 0 -8px;
}

.circleOnPlan {
    background: url(/src/images/circle.svg) no-repeat;
    position: absolute;
    height: 30px;
    width: 30px;
    background-size: 30px;
    margin: -15px 0 0 -15px;
    cursor: pointer;
    /* color: #FFF; */
}

.sensorOnPlan {
    background: url(/src/images/sensor.svg) no-repeat;
    position: absolute;
    height: 50px;
    width: 25px;
    background-size: 25px;
    margin: -25px 0 0 -12px;
    cursor: pointer;
    color: #FFF;
}

.leakageSensorOnPlan {
    background: url(/src/images/leakage-sensor.svg) no-repeat;
    position: absolute;
    height: 50px;
    width: 25px;
    background-size: 25px;
    margin: -25px 0 0 -12px;
    cursor: pointer;
    color: #FFF;
}

.valveNbiotOnPlan {
    background: url(/src/images/gidrolock.svg) no-repeat;
    position: absolute;
    height: 60px;
    width: 25px;
    background-size: 25px;
    margin: -30px 0 0 -12px;
    cursor: pointer;
    color: #FFF;
}

.counterElectroOnPlan {
    background: url(/src/images/counter-electro.svg) no-repeat;
    position: absolute;
    height: 75px;
    width: 40px;
    background-size: 40px;
    margin: -36px 0 0 -20px;
    cursor: pointer;
    color: #FFF;
}

.counterColdOnPlan {
    background: url(/src/images/counter-water-cold.svg) no-repeat;
    position: absolute;
    height: 75px;
    width: 40px;
    background-size: 40px;
    margin: -36px 0 0 -20px;
    cursor: pointer;
    color: #FFF;
}

.counterHotOnPlan {
    background: url(/src/images/counter-water-hot.svg) no-repeat;
    position: absolute;
    height: 75px;
    width: 40px;
    background-size: 40px;
    margin: -36px 0 0 -20px;
    cursor: pointer;
    color: #FFF;
}

.counterGazOnPlan {
    background: url(/src/images/counter-gaz.svg) no-repeat;
    position: absolute;
    width: 40px;
    height: 75px;
    background-size: 40px;
    margin: -36px 0 0 -20px;
    cursor: pointer;
    color: #FFF;
}

.counterColorOnPlan {
    background: url(/src/images/counter-color.svg) no-repeat;
    position: absolute;
    width: 40px;
    height: 75px;
    background-size: 40px;
    margin: -36px 0 0 -20px;
    cursor: pointer;
    color: #FFF;
}