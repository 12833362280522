.searchInputObjRes {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    position: absolute;
    z-index: 20;
    max-height: 185px;
    overflow-y: auto;
    right: calc(var(--bs-gutter-x) * .5);
    left: calc(var(--bs-gutter-x) * .5);
}

.searchInputObjResPointer {
    padding: 2px 5px;
    cursor: pointer;
}

.searchInputObjResPointer:hover {
    background-color: #e8e8e8;
}

.searchInputObj {
    position: relative;
}

.searchInputObjLabel {
    margin-bottom: .5rem;
}

.searchInputObjInputGroup {
    z-index: 20;
}
