.textarea {
    width: 100%;
    min-height: 30vh;
    max-height: 60vh;
    overflow-x: hidden;
}

.count, .help {
    font-style: italic;
    float: right;
}